import { Controller } from '@hotwired/stimulus';

import Glide, {
	Breakpoints,
	Controls,
	Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";

export default class extends Controller {
  glide = null;

  connect() {
    console.log('glide');
    let self = this;

    this.glide = new Glide(this.element, {
      autoplay: true,
      animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
      animationDuration: 1200,
      peek: 0,
      perView: 1,
      gap: 0,
      type: "carousel",
      swipeThreshold: 80,
      dragThreshold: 80,
      breakpoints: {
        640: {
          gap: 0,
          perView: 1,
        },
      },
    }).mount({
      Controls,
      Breakpoints,
      Swipe,
    });
  }

}
